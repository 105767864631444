import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?p=217

const EventOtazkovy: FC = () => {
  return (
    <PageTemplate>
      <PostHeader
        title="OTÁZKOVÝ"
        link="/event_otazkovy"
        author="Algy"
        date="18.03.2014"
        time="13:23"
        category="Eventy"
      />
      <p><strong>OTÁZKOVÝ EVENT</strong></p>
      <p>GM pokládá v announcement otázky z různých oblastí. Event čítá vždy 15 otázek, přitom za správné zodpovězení hráč obdrží bod. Některé těžké otázky může po předchozím upozornění GM honorovat i více body. Vyhrává hráč s nejvyšším počtem bodů. V případě rovnoti bodů GM položí další otázku/y k určení konečného pořadí.</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default EventOtazkovy

export { Head } from '../components/defaultHtmlHead'